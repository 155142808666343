import { Component, OnInit } from '@angular/core';
import { CargarMotivoService } from './cargar-motivo.service';
import { ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-cargar-motivo',
  templateUrl: './cargar-motivo.page.html',
  styleUrls: ['./cargar-motivo.page.scss'],
})
export class CargarMotivoPage implements OnInit {

  nombre: string;
  motivo: string;
  email: string;
  telefono: string;
  oracionpor: string;
  playerId: string;


  constructor(
              public toastCtrl: ToastController,
              public cargarMotivoService: CargarMotivoService,
              private storage: Storage) {

                this.levantarDatosGuardados();
  }

  enviarMotivo(){
    console.log('estoy dentro de la función enviarMotivo()');
    // debugger;
    if (this.nombre !== '' && this.motivo !== '' && this.telefono !== '' && this.email !== ''
      && this.oracionpor !== '' && this.email != null && this.nombre != null && this.motivo != null
      && this.telefono != null && this.oracionpor != null) {

        const motivo = {
          nombre: this.nombre,
          motivo: this.motivo,
          email: this.email,
          telefono: this.telefono,
          oracionpor: this.oracionpor,
          playerid: this.playerId
      };

        this.storage.set('nombre', this.nombre);
        this.storage.set('email', this.email);
        this.storage.set('telefono', this.telefono);

        console.log('estoy FUERA del if');
        console.log(this.motivoToString());

        // comento para que no publique los motivos en la API, solo para probar el local storage.
        this.cargarMotivoService.postMotivo(motivo);


        this.mostrarToast('Su motivo ha sido enviado');
        this.limpiarFormulario();
        this.verDatosGuardados();

   } else {
      this.mostrarToast('Por favor complete los campos obligatorios');
      this.verDatosGuardados();
    }

  }

 async mostrarToast(mensaje) {
  const toast = await this.toastCtrl.create({
    message: mensaje,
    duration: 3000
  });
  toast.present();
}

  limpiarFormulario(){
    // this.nombre = '';
    // this.email = '';
    // this.telefono = '';
    this.oracionpor = '';
    this.motivo = '';
  }

  motivoToString(){
    return 'Nuevo motivo de oración por ' + this.oracionpor + ' de ' + this.nombre
            + '. MOTIVO: ' + this.motivo + '. Teléfono: ' + this.telefono + '. email:'
            + this.email + '\n'
            + this.playerId;
  }

  levantarDatosGuardados() {
    this.storage.get('nombre').then((val) => {
      console.log('Your name is: ', val);
      this.nombre = val;
    });
    this.storage.get('email').then((val) => {
      console.log('Your email is: ', val);
      this.email = val;
    });
    this.storage.get('telefono').then((val) => {
      console.log('Your telephone is: ', val);
      this.telefono = val;
    });
    this.storage.get('player_id').then((val) => {
      console.log('Your player_id is: ', val);
      this.playerId = val;
    });
  }


  verDatosGuardados() {
    this.storage.get('nombre').then((val) => {
      console.log('Your name is: ', val);
    });
    this.storage.get('email').then((val) => {
      console.log('Your email is: ', val);
    });
    this.storage.get('telefono').then((val) => {
      console.log('Your telephone is: ', val);
    });
    this.storage.get('player_id').then((val) => {
      console.log('Your playerid is: ', val);
    });
  }


  ngOnInit() {
  }

}
