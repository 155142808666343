import { Injectable } from '@angular/core';
import { CacheService } from 'ionic-cache';
import { HttpClient } from '@angular/common/http';
import { RequestOptions, Headers } from '@angular/http';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { API_INTERESADOS, API_ENCUENTROS, API_BUSQUEDA_ENCUENTROS, API_WP_ENCUENTROS } from '../../../config';
import { tap } from 'rxjs/operators';

@Injectable()
export class EncuentrosService {

  listadoEncuentros: any;
  url = API_ENCUENTROS;
  urlInteresados = API_INTERESADOS;
  urlBusqueda = API_BUSQUEDA_ENCUENTROS;

  wpUrl = API_WP_ENCUENTROS;


  constructor(private http: HttpClient, private cache: CacheService) {  }

  public getDatosEncuentros(){
    return this.http.get(this.url).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return Observable.throw('Something went wrong ;)');
      })
    );
  }

  public buscarEncuentrosByGeneroEdad(genero, edad){
    return this.http.get(this.urlBusqueda + '/' + genero + '/' + edad).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return Observable.throw('Something went wrong ;)');
      })
    );
  }

/********************************************************** */

// Load either from API or Cache
public loadListadoEncuentros(refresher?) {
  this.http.get(this.url).pipe(
    map(data => {
      return data;
    }),
    catchError(error => {
      return Observable.throw('Something went wrong ;)');
    })
  );

  const ttl = 5;

  if (refresher) {
    console.log('Buscando novedades en la API, a pedido del usuario.');
    const delayType = 'all';
    this.listadoEncuentros = this.cache.loadFromDelayedObservable(this.url, this.http.get(this.url), null, ttl, delayType);
    this.listadoEncuentros.subscribe(data => {
      refresher.target.complete();
    });

  } else {
    console.log('Buscando novedades en la API');
    this.listadoEncuentros = this.cache.loadFromObservable(this.url, this.http.get(this.url));
  }

  return this.listadoEncuentros;
  }



  // public loadListadoEncuentrosWp(pagina, refresher?) {
  //   this.http.get(this.wpUrl).pipe(
  //     map(data => {
  //       return data;
  //     }),
  //     catchError(error => {
  //       return Observable.throw('Something went wrong ;)');
  //     })
  //   );
  
  //   const ttl = 5;
  
  //   if (refresher) {
  //     console.log('Buscando novedades en la API, a pedido del usuario.');
  //     const delayType = 'all';
  //     // tslint:disable-next-line: max-line-length
  //     this.listadoEncuentros = this.cache.loadFromDelayedObservable(this.wpUrl + pagina, this.http.get(this.wpUrl + pagina), null, ttl, delayType);
  //     this.listadoEncuentros.subscribe(data => {
  //       refresher.target.complete();
  //     });
  
  //   } else {
  //     console.log('Buscando novedades en la API');
  //     this.listadoEncuentros = this.cache.loadFromObservable(this.wpUrl + pagina, this.http.get(this.wpUrl + pagina));
  //   }
  
  //   return this.listadoEncuentros;
  //   }

  public loadListadoEncuentrosWp(pagina, refresher?) {
    this.http.get(this.wpUrl).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return Observable.throw('Something went wrong ;)');
      })
    );

    const ttl = 5;

    if (refresher) {
      console.log('Buscando novedades en la API, a pedido del usuario.');
      const delayType = 'all';
      // tslint:disable-next-line: max-line-length
      this.listadoEncuentros = this.cache.loadFromDelayedObservable(this.wpUrl + pagina, this.http.get(this.wpUrl + pagina), null, ttl, delayType);
      this.listadoEncuentros.subscribe(data => {
        refresher.target.complete();
      });

    } else {
      console.log('Buscando novedades en la API');
      this.listadoEncuentros = this.cache.loadFromObservable(this.wpUrl + pagina, this.http.get(this.wpUrl + pagina));
    }

    return this.listadoEncuentros;
    }


    public getPaginas() {
    return this.http.get<any[]>(`${this.wpUrl}1`,{ observe: 'response' }).pipe(tap(res => {
      const paginasTotal  = res.headers.get('x-wp-totalpages');
      console.log('muestro mi total de paginas traido desde el header: ' + paginasTotal);
      return paginasTotal;
    }));

    }



}
