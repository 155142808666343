import { Component } from '@angular/core';
/************************* */
// import { NavController, NavParams, AlertController } from 'ionic-angular';
import { HttpModule } from '@angular/http';
import { CargarMotivoPage } from '../cargar-motivo/cargar-motivo.page';
import { VersiculoDelDiaPage } from '../versiculo-del-dia/versiculo-del-dia.page';
import { NovedadesPage } from '../novedades/novedades.page';
import { IglesiaPage } from '../iglesia/iglesia.page';
import { TvPage } from '../tv/tv.page';
import { TvService } from '../tv/tv.service';
// import 'rxjs/add/operator/map';
import { EncuentroAmigosPage } from '../encuentro-amigos/encuentro-amigos.page';
import { TransmisionVivoPage } from '../transmision-vivo/transmision-vivo.page';

import { Router, ActivatedRoute } from '@angular/router';
import { AlertController, Platform } from '@ionic/angular';


/**************************/
@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {

  // splash habilita que se muestre la pantalla de bienvenida con el logo.
  splash = true;
  subscription: any ;


  constructor(public tvService: TvService, private router: Router, public platform: Platform,
              public alertCtrl: AlertController, public http: HttpModule) {

      /* // Método para deshabilitar splash screen cuando se navega desde el menú lateral.
      let origen = navParams.get("origen");
      if(origen =='sideMenu'){
        this.splash = false;
      }  */


    }

  ionViewDidLoad() {
    setTimeout(() => this.splash = false, 4000);

  }

  ionViewDidLeave() {
    this.splash = false;
    console.log('Estoy saliendo de la pagina home');
  }


  ionViewDidEnter(){
    this.subscription = this.platform.backButton.subscribe(() => {
        navigator['app'].exitApp();
    });
}

  ionViewWillLeave(){
      this.subscription.unsubscribe();
  }

}
