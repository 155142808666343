import { Component, OnInit } from '@angular/core';
import { TvService } from './tv.service';
import { Platform } from '@ionic/angular';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { YoutubeVideoPlayer } from '@ionic-native/youtube-video-player/ngx';
import { APP_TELEVISION_URL } from '../../../config';


@Component({
  selector: 'app-tv',
  templateUrl: './tv.page.html',
  styleUrls: ['./tv.page.scss'],
})
export class TvPage implements OnInit {

  videos: any;

  constructor(private socialSharing: SocialSharing,
              public tvService: TvService,
              private plt: Platform,
              private youtube: YoutubeVideoPlayer) { }

  ngOnInit() {
    console.log('estoy dentro del ngOnInit tv page.');
    this.tvService.getVideosTv().subscribe((v) => {
      this.videos = v;
    });
  }

  openVideo(videoId) {
    if (this.plt.is('cordova')) {
      this.youtube.openVideo(videoId.videoId);
    } else {
      window.open('https://www.youtube.com/watch?v=' + videoId.videoId);
    }
  }

  compartir(v: any){
    let tituloMayuscula: string;
    tituloMayuscula = v.snippet.title;
    const linkDescarga = 'Mirá este video desde la app -> ' + APP_TELEVISION_URL;
    this.socialSharing.share(
      'Te comparto este video!' +
      '\n' + tituloMayuscula.toLocaleUpperCase() + '\n' +
      'https://www.youtube.com/watch?v=' + v.snippet.resourceId.videoId +
      '\nCompartido desde EncuentroVida App.',
  v.snippet.title,
  '', linkDescarga);
  }

}
