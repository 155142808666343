import { Component, OnInit } from '@angular/core';
import { AudioPredicasService} from './audio-predicas.service';
 
@Component({
  selector: 'app-audio-predicas',
  templateUrl: './audio-predicas.page.html',
  styleUrls: ['./audio-predicas.page.scss'],
})
export class AudioPredicasPage implements OnInit {

  audioPredicas: any;
  selectedAudio: any;
  action: any;
  isPlaying: any;

  constructor(public audioPredicasService: AudioPredicasService) {
    console.log('Llamando al servicio para obtener novedades');
    this.audioPredicasService.loadAudios(false).subscribe(
      data => {
        console.log(data);
        this.audioPredicas = data;
      },
      err => console.error(err),
      () => console.log('loadAudios completado')
    );
    console.log(this.audioPredicas);
   }

   ionRefresh(event) {
    console.log('Pull Event Triggered!');
    setTimeout(() => {
      console.log('Async operation has ended');
      this.audioPredicasService.loadAudios(event).subscribe(
        (        data: { json: () => void; }) => {
          this.audioPredicas = data;
        },
        err => console.error(err),
        () => console.log('loadAudios completed')
      );
      // complete()  signify that the refreshing has completed and to close the refresher
      event.target.complete();
    }, 2000);
  }

  formatDate(date) {
    const d =  new Date(date);

    const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo',
    'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    const dayNames = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    return dayNames[d.getUTCDay()] + ' ' + d.getUTCDate() + ' de ' + monthNames[d.getMonth()] + ', ' + d.getFullYear();
    }

  ngOnInit() {
  }



}
