import { Injectable } from '@angular/core';
import { CacheService } from 'ionic-cache';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { API_NOVEDADES, API_WP_NOVEDADES } from '../../../config';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NovedadesService {

  novedades: any = [];
  url = API_NOVEDADES;
  wpUrl = API_WP_NOVEDADES;
  req = this.http.get(this.url);

  

  constructor(private http: HttpClient, private cache: CacheService) {

  }

  // public loadNovedadesWP(refresher?) {
  //   this.http.get(this.wpUrl).pipe(
  //     map(data => {
  //       return data;
  //     }),
  //     catchError(error => {
  //       return Observable.throw('Something went wrong ;)');
  //     })
  //   );

  //   const ttl = 5;

  //   if (refresher) {
  //     console.log('Buscando novedades en la API, a pedido del usuario.');
  //     const delayType = 'all';
  //     this.novedades = this.cache.loadFromDelayedObservable(this.wpUrl, this.http.get(this.wpUrl), null, ttl, delayType);
  //     this.novedades.subscribe(data => {
  //       refresher.target.complete();
  //     });

  //   } else {
  //     console.log('Buscando novedades en la API');
  //     this.novedades = this.cache.loadFromObservable(this.wpUrl, this.http.get(this.wpUrl));
  //   }

  //   return this.novedades;
  //   }

  public loadNovedadesWP(pagina, refresher?) {
    this.http.get(this.wpUrl).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return Observable.throw('Something went wrong ;)');
      })
    );

    const ttl = 5;

    if (refresher) {
      console.log('Buscando novedades en la API, a pedido del usuario.');
      const delayType = 'all';
      // tslint:disable-next-line: max-line-length
      this.novedades = this.cache.loadFromDelayedObservable(this.wpUrl + pagina, this.http.get(this.wpUrl + pagina), null, ttl, delayType);
      this.novedades.subscribe(data => {
        refresher.target.complete();
      });

    } else {
      console.log('Buscando novedades en la API');
      this.novedades = this.cache.loadFromObservable(this.wpUrl + pagina, this.http.get(this.wpUrl + pagina));
    }

    return this.novedades;
    }


    public getPaginas() {
    return this.http.get<any[]>(`${this.wpUrl}1`,{ observe: 'response' }).pipe(tap(res => {
      const paginasTotal  = res.headers.get('x-wp-totalpages');
      console.log('muestro mi total de paginas traido desde el header: ' + paginasTotal);
      return paginasTotal;
    }));

    }
} 
