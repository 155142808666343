import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { resolve } from 'url';
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { tap } from 'rxjs/operators';
import { API_VERSICULOS_INDIVIDUAL } from '../../config';


@Injectable({
  providedIn: 'root'
})
export class VersiculoResolveService implements Resolve<any> {

  constructor(private http: HttpClient, private loadingController: LoadingController) {

  }


  resolve(route: ActivatedRouteSnapshot) {
    let loading: HTMLIonLoadingElement;

    const id = route.paramMap.get('id');

    this.loadingController.create({
       message: 'Cargando...'
    }).then(res => {
      loading = res;
      loading.present();
    });

    return this.http.get(API_VERSICULOS_INDIVIDUAL + `${id}/`)
    .pipe(
      tap(() => {
      loading.dismiss();
      })
    );
    }

}