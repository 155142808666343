import { Injectable } from '@angular/core';
import { RequestOptions, Headers, Http } from '@angular/http';
import { API_MOTIVOS_ORACION } from '../../../config';




@Injectable({
  providedIn: 'root'
})
export class CargarMotivoService {

  constructor(private http: Http, ) { }


  postMotivo(data: any) {
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json' );
    const requestOptions = new RequestOptions({ headers: headers });
    this.http.post(API_MOTIVOS_ORACION, data, requestOptions)
      .subscribe(data => {
        console.log(data['body']);
       }, error => {
        console.log(error);
      });
  }
}
