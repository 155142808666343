import { Component, OnInit, ViewChild } from '@angular/core';
import { VersiculoDelDiaService } from './versiculo-del-dia.service';
import { VersiculoPopoverPage } from '../versiculo-popover/versiculo-popover.page';
import { IonInfiniteScroll } from '@ionic/angular';

@Component({
  selector: 'app-versiculo-del-dia',
  templateUrl: './versiculo-del-dia.page.html',
  styleUrls: ['./versiculo-del-dia.page.scss'],
})
export class VersiculoDelDiaPage implements OnInit {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  versiculos: any;
  version: any;
  data: any;
  pagina = 1 ;
  paginasTotal: number;
  hayProxima: boolean;
  totalDocs: number;

  constructor(public versiculoDelDiaService: VersiculoDelDiaService) {
  }


  ngOnInit() {
    this.versiculoDelDiaService.loadVersiculosPagination(this.pagina, false).subscribe(
      data => {
        this.versiculos = data.docs;
        this.paginasTotal = data.totalPages;
        this.pagina = data.page;
        this.hayProxima = data.hasNextPage;
        this.totalDocs = data.totalDocs;
      },
      err => console.error(err),
      () => console.log('loadVersiculos completado')
    );

  }


  public ionViewWillEnter() {
  }

  public cargarMas(event) {
    this.pagina++;
    console.log('Total Pags:  ' + this.paginasTotal);
    console.log('Pagina:  ' + this.pagina);
    if (this.hayProxima === false) {
      event.target.complete();
      this.infiniteScroll.disabled = true;
      return;
    }
    this.versiculoDelDiaService.loadVersiculosPagination(this.pagina, false).subscribe(
      data => {
        this.versiculos = [...this.versiculos, ...data.docs];
        this.paginasTotal = data.totalPages;
        this.pagina = data.page;
        this.hayProxima = data.hasNextPage;
        this.totalDocs = data.totalDocs;
      },
      err => console.error(err),
      () => console.log('loadVersiculos completado')
    );
    event.target.complete();
  }


  loadNovedades(event) {
    console.log('Pull Event Triggered!');
    setTimeout(() => {
      console.log('Async operation has ended');
      this.versiculoDelDiaService.loadNovedades(1, event).subscribe(
        // (        data: { json: () => void; }) => {
        data => {
          this.versiculos = data.docs;
        },
        err => console.error(err),
        () => console.log('buscarNovedades completed')
      );
      event.target.complete();
      // Reinicio el estado del infinite scroll
      this.infiniteScroll.disabled = false;
      this.pagina = 1;
      this.hayProxima = true;
    }, 2000);
  }

 formatDate(date) {
    const d =  new Date(date);
    const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto',
                     'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const dayNames = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    return dayNames[d.getUTCDay()] + ' ' + d.getUTCDate() + ' de ' + monthNames[d.getUTCMonth()] + ', ' + d.getUTCFullYear();
    }



}
