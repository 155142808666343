import { Component, ViewChild } from '@angular/core';
import { IonNav, Platform, AlertController, ModalController  } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

// IMPORTS DE PÁGINAS
// import {  } from './';
import { AcercaDePage } from './pages/acerca-de/acerca-de.page';
import { CargarMotivoPage } from './pages/cargar-motivo/cargar-motivo.page';
import { EncuentroAmigosPage } from './pages/encuentro-amigos/encuentro-amigos.page';
import { HomePage } from './pages/home/home.page';
import { IglesiaPage } from './pages/iglesia/iglesia.page';
import { NovedadesPage } from './pages/novedades/novedades.page';
import { QuieroAsistirPage } from './pages/quiero-asistir/quiero-asistir.page';
import { TransmisionVivoPage } from './pages/transmision-vivo/transmision-vivo.page';
import { TvPage } from './pages/tv/tv.page';
import { VersiculoDelDiaPage } from './pages/versiculo-del-dia/versiculo-del-dia.page';
import { VersiculoPopoverPage } from './pages/versiculo-popover/versiculo-popover.page';


// IMPORTS DE SERVICIOS
import { TvService } from './pages/tv/tv.service';
import { IglesiaService } from './pages/iglesia/iglesia.service';
import { CargarMotivoService } from './pages/cargar-motivo/cargar-motivo.service';
import { BosquejosService } from './pages/encuentro-amigos/bosquejos.service';
import { NovedadesService } from './pages/novedades/novedades.service';
import { TransmisionVivoService } from './pages/transmision-vivo/transmision-vivo.service';
import { VersiculoDelDiaService } from './pages/versiculo-del-dia/versiculo-del-dia.service';



// MODULOS A IMPORTAR INSTALAR Y AGREGAR:
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { CacheService } from 'ionic-cache';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { OneSignal, OSNotificationPayload } from '@ionic-native/onesignal/ngx';
import { isCordovaAvailable } from './common/is-cordova-available';
import { oneSignalAppId, sender_id, ASSET_APP_SHARE_GIF, APP_INSTALL_URL } from '../config';

import { MenuController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { NgZone } from '@angular/core';
import { VersiculoDelDiaPageModule } from './pages/versiculo-del-dia/versiculo-del-dia.module';
import { VersiculoResolveService } from './resolver/versiculo-resolve.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  @ViewChild(IonNav) nav: IonNav;

  rootPage: any =  HomePage;
  // rootPage: any =  LoginPage;

  // pages: Array<{icon: string, title: string, component: any}>;

  urlWebIglesia = 'http://encuentrovida.com.ar';
  urlWebLearning = 'http://learning.encuentrovida.com.ar';
  notificaciones = true;
  public style: string;

  options: InAppBrowserOptions = {
    location : 'yes', // Or 'no'
    hidden : 'no', // Or  'yes'
    clearcache : 'yes',
    clearsessioncache : 'yes',
    zoom : 'no', // Android only ,shows browser zoom controls
    hardwareback : 'yes',
    mediaPlaybackRequiresUserAction : 'no',
    shouldPauseOnSuspend : 'no', // Android only
    closebuttoncaption : 'Close', // iOS only
    disallowoverscroll : 'no', // iOS only
    toolbar : 'yes', // iOS only
    enableViewportScale : 'no', // iOS only
    allowInlineMediaPlayback : 'no', // iOS only
    presentationstyle : 'pagesheet', // iOS only
    fullscreen : 'yes', // Windows only
  };

  constructor(
    private platform: Platform,
    // private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private menu: MenuController,
    public cache: CacheService,
    public alertCtrl: AlertController,
    public router: Router,
    private inAppBrowser: InAppBrowser,
    private socialSharing: SocialSharing,
    private oneSignal: OneSignal,
    private modalCtrl: ModalController,
    private storage: Storage,
    private tvService: TvService,
    private deeplinks: Deeplinks,
    private zone: NgZone) {

      this.initializeApp();

  }


goMotivos() {
    this.router.navigate(['/cargar-motivo']);
    this.menu.close();
  }
goHome() {
    this.router.navigate(['/home']);
    this.menu.close();
  }
goNovedades() {
    this.router.navigate(['/novedades']);
    this.menu.close();
  }
goDevocional() {
    this.router.navigate(['/versiculo-del-dia']);
    this.menu.close();
  }
goEncuentros() {
    this.router.navigate(['/encuentro-amigos']);
    this.menu.close();
  }
goPredicas() {
    this.router.navigate(['/transmision-vivo']);
    this.menu.close();
  }
goIglesia() {
    this.router.navigate(['/iglesia']);
    this.menu.close();
  }
goTv() {
    this.router.navigate(['/tv']);
    this.menu.close();
  }
goAdminLogin(){
    this.router.navigate(['/admin-login']);
    this.menu.close();
  }


goWebsite() {
    const browser = this.inAppBrowser.create(this.urlWebIglesia, null, this.options);
    browser.show();
    this.menu.close();
  }
goWebsiteCapacitacion() {
    const browser = this.inAppBrowser.create(this.urlWebLearning, null, this.options);
    browser.show();
    this.menu.close();
  }


initializeApp() {
    this.platform.ready().then(() => {
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.
      this.statusBar.styleDefault();
      // this.addStyle();
      // this.splashScreen.hide(); // REMOVIDO POR TUTORIAL, PARA PROBAR SI DESAPARECE AL REABRIR LA HOMEPAGE
      // Set TTL to 12h
      this.cache.setDefaultTTL(60 * 60 * 12);
      // Keep our cached results when device is offline!
      this.cache.setOfflineInvalidate(false);


      // OneSignal configuration
      if (isCordovaAvailable()) {
        this.oneSignal.startInit(oneSignalAppId, sender_id);

        this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);
        this.oneSignal.handleNotificationReceived().subscribe(data => this.onPushReceived(data.payload));
        this.oneSignal.handleNotificationOpened().subscribe(data => this.onPushOpened(data.notification.payload));

        /********************* */
       // this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
       /* this.oneSignal.handleNotificationReceived().subscribe(data => {
          const msg = data.payload.body;
          const title = data.payload.title;
          const additionalData = data.payload.additionalData;
          // debugger;
          this.showAlert(title, msg, additionalData);
        }); */

        // Notification was really clicked/opened
/*        this.oneSignal.handleNotificationOpened().subscribe(data => {
          // Just a note that the data is a different place here!
          const additionalData = data.notification.payload.additionalData;

          this.showAlert('Notification opened', 'You already read this before', additionalData);
        });
*/
        /************************ */

        this.oneSignal.enableVibrate(true);
        this.oneSignal.endInit();

        this.oneSignal.getIds().then((id) => {
          console.log('El player id de onesignal es: ' + id.userId);
          const auxString = JSON.stringify(id.userId).replace(/"/g, '');
          this.storage.set('player_id', auxString);

        });

        /* TODO: verificar la suscripción a notificaciones push en OneSignal */

        /* TODO: Enviar aqui un TAG para identificar al usuario y poder mandar push notifications selectivas... */

        this.setupDeeplinks();

      }
    });
  }

  setupDeeplinks() {
    // this.deeplinks.route({ '/:slug': 'posts' }).subscribe(
      this.deeplinks.route({
        '/o': '',
        '/v/:id': '',
        '/e': '',
        '/t': '',
        '/n': '',
        '/a': '',
        '/tv': ''
      }).subscribe(
      match => {
        console.log('Successfully matched route', match);
        const wildcard = '/v/*';
        let str: string ;
        switch (String(match.$link.path)) {
          case '/v/*':
            str = match.$link.path.replace('/v/', '/versiculo-del-dia/');
            console.log('estoy dentro del primer case con condición \'/v/*\'');
            console.log('Muestro mi path: ' + String(match.$link.path));
            break;
          case '/o':
            str = match.$link.path.replace('/o', '/cargar-motivo');
            break;
          case '/t':
            str = match.$link.path.replace('/t', '/transmision-vivo');
            break;
          case '/e':
            str = match.$link.path.replace('/e', '/encuentro-amigos');
            break;
          case '/n':
            str = match.$link.path.replace('/n', '/novedades');
            break;
          case '/a':
            str = match.$link.path.replace('/a', '/audio-predicas');
            break;
          case '/tv':
            str = match.$link.path.replace('/tv', '/tv');
            break;
          default:
            console.log('salgo por el default');
            console.log('Muestro mi path: ' + String(match.$link.path));
            str = match.$link.path.replace('/v/', '/versiculo-del-dia/');
            break;
        }

        // const str = match.$link.path.replace('/v/', '/versiculo-del-dia/');
        // Create our internal Router path by hand
        // const internalPath = `/${match.$route}/${match.$args['']}`;

        // const internalPath = `/${match.$link.path}`;
        const internalPath = `/${str}`;
        console.log('muestro mi internalPath: ' + internalPath);

        // Run the navigation in the Angular zone
        this.zone.run(() => {
          this.router.navigateByUrl(internalPath);
        });
      },
      nomatch => {
        // nomatch.$link - the full link data
        console.error('Got a deeplink that didn\'t match' + nomatch.$link);
      }
    );
  }


  async showAlert(title, msg, task) {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: `Action: ${task}`,
          handler: () => {
            // E.g: Navigate to a specific screen
          }
        }
      ]
    });
    alert.present();
  }

  private onPushReceived(payload: OSNotificationPayload) {
    /*
    alert('Push recevied:' + payload.body);
    debugger;
    */
  }

  private onPushOpened(payload: OSNotificationPayload) {
    console.log('estoy dentro de onPushOpened()');
    console.log(payload);
    if (payload.additionalData.tipo === 'novedad') {
      this.router.navigate(['/novedades']);
    }
    if (payload.additionalData.tipo === 'oracion') {
      this.router.navigate(['/cargar-motivo']);
    }
    if (payload.additionalData.tipo === 'tv') {
      this.router.navigate(['/tv']);
    }
    if (payload.additionalData.tipo === 'encuentro-amigos') {
      this.router.navigate(['/encuentro-amigos']);
    }
    if (payload.additionalData.tipo === 'versiculo') {
      if (payload.additionalData.idVersiculo) {
        this.router.navigate(['/versiculo-del-dia/' + payload.additionalData.idVersiculo]);
      } else {
        this.router.navigate(['/versiculo-del-dia/']);
      }
    }
    if (payload.additionalData.tipo === 'transmision-vivo') {
      this.router.navigate(['/transmision-vivo']);
    }
  }


  /* presentConfirm() {
    let alert = this.alertCtrl.create({
      title: 'Falta poco...',
      message: 'Muy pronto estará disponible esta función',
      buttons: [
          {
            text: 'Ok',
            role: 'cancel',
            handler: () => {
              console.log('Dialogo confirmado');
            }
          }
        ]
      });

      alert.present();

    } */


compartirApp() {
    this.socialSharing.share(
          'Te comparto esta aplicación que me es de bendición! Instalala en tus dispositivos!',
          'Encuentro con la Vida APP',
          ASSET_APP_SHARE_GIF,
          APP_INSTALL_URL);
  }




}
