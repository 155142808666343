import { Component, OnInit, ViewChild } from '@angular/core';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { AlertController, ToastController } from '@ionic/angular';
import { NovedadesService } from './novedades.service';
import { APP_NOVEDADES_URL } from '../../../config';
import { IonInfiniteScroll } from '@ionic/angular';

@Component({
  selector: 'app-novedades',
  templateUrl: './novedades.page.html',
  styleUrls: ['./novedades.page.scss'],
})
export class NovedadesPage implements OnInit {

  novedades: any = [];
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  pagina = 1 ;
  paginasTotal: number;

  constructor(private socialSharing: SocialSharing,
              public alertCtrl: AlertController,
              public novedadesService: NovedadesService,
              private toastCtrl: ToastController) {

  }

  ngOnInit(): void {
    console.log('Llamando al servicio para obtener novedades');
    // Traigo la cantidad de paginas totales de wordpress. Dato que viene en el header http.
    this.novedadesService.getPaginas().subscribe(
      paginas => {
        // tslint:disable-next-line: radix
        this.paginasTotal = Number.parseInt(paginas.headers.get('x-wp-totalpages'));
        console.log(this.paginasTotal);

      },
      err => console.error(err),
      () => console.log('getPaginas completado')
    );
    // Traigo los datos de novedades desde la API
    console.log('Llamando al servicio para obtener novedades');
    this.novedadesService.loadNovedadesWP(this.pagina, false).subscribe(
      data => {
        this.novedades = data;
      },
      err => console.error(err),
      () => console.log('loadNovedades completado')
    );
  }

  formatDate(date) {
    const d =  new Date(date);

    const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo',
    'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    const dayNames = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    return dayNames[d.getUTCDay()] + ' ' + d.getUTCDate() + ' de ' + monthNames[d.getMonth()] + ', ' + d.getFullYear();
    }

  compartir(novedad: any) {
    // share(message, subject, file, url)
    let tituloMayuscula: string;
    // tituloMayuscula = novedad.titulo;  //Titulo evida-API

    tituloMayuscula = novedad.title.rendered; // Titulo WP_API
    const linkDescarga = 'Mira las novedades desde la app -> ' + APP_NOVEDADES_URL;
    this.socialSharing.share(
          'Te comparto este evento donde me gustaría que me acompañes!' +
          '\n\n' + tituloMayuscula.toLocaleUpperCase() + '\n' +
          // '\n' + novedad.texto + '\n' + // texto evida-API
          '\n' + novedad.descripcion + '\n' + // texto WP-API
          '\nEl día ' + this.formatDate(novedad.fecha) + ', ' + novedad.horario + ' hs.\n' +
          '\nCompartido desde EncuentroVida App.',
      novedad.titulo,
      novedad.fotourl,
      linkDescarga);
    }

  ionRefresh(event) {
    console.log('Pull Event Triggered!');
    setTimeout(() => {
      console.log('Async operation has ended');
      this.novedadesService.loadNovedadesWP(1, event).subscribe(
        (        data: { json: () => void; }) => {
          this.novedades = data;
        },
        err => console.error(err),
        () => console.log('buscarNovedades completed')
      );
      // complete()  signify that the refreshing has completed and to close the refresher
      this.infiniteScroll.disabled = false;
      this.pagina = 1;
      event.target.complete();
    }, 2000);
  }

  public cargarMas(event) {
    this.pagina++;
    console.log('Total Pags:  ' + this.paginasTotal);
    console.log('Pagina:  ' + this.pagina);
    if (this.pagina > this.paginasTotal) {
      event.target.complete();
      this.infiniteScroll.disabled = true;
      return;
    }
    this.novedadesService.loadNovedadesWP(this.pagina, false).subscribe(
      data => {
        this.novedades = [...this.novedades, ...data];
      },
      err => console.error(err),
      () => console.log('loadVersiculos completado')
    );
    event.target.complete();
  }
}
