import { Component, OnInit } from '@angular/core';
import { VersiculoDelDiaService } from '../versiculo-del-dia/versiculo-del-dia.service';
import { ActivatedRoute } from '@angular/router';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { InAppBrowserOptions, InAppBrowser, InAppBrowserObject } from '@ionic-native/in-app-browser/ngx';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { Platform } from '@ionic/angular';
import { APP_VERSICULO_URL } from '../../../config';

@Component({
  selector: 'app-versiculo-popover',
  templateUrl: './versiculo-popover.page.html',
  styleUrls: ['./versiculo-popover.page.scss'],
})
export class VersiculoPopoverPage implements OnInit {

  versiculo = null;
  fontSize = 1; // default font size in `em`

  options: InAppBrowserOptions = {
    location : 'yes', // Or 'no'
    hidden : 'no', // Or  'yes'
    clearcache : 'yes',
    clearsessioncache : 'yes',
    zoom : 'no', // Android only ,shows browser zoom controls
    hardwareback : 'yes',
    mediaPlaybackRequiresUserAction : 'no',
    shouldPauseOnSuspend : 'no', // Android only
    closebuttoncaption : 'Close', // iOS only
    disallowoverscroll : 'no', // iOS only
    toolbar : 'yes', // iOS only
    enableViewportScale : 'no', // iOS only
    allowInlineMediaPlayback : 'no', // iOS only
    presentationstyle : 'pagesheet', // iOS only
    fullscreen : 'yes', // Windows only
  };

  constructor(public versiculoDelDiaService: VersiculoDelDiaService,
              private route: ActivatedRoute,
              public inAppBrowser: InAppBrowser,
              private platform: Platform,
              private appAvailability: AppAvailability,
              private socialSharing: SocialSharing) { }

  ngOnInit() {
    // Me traigo la data del versiculo / devocional.
    console.log(this.route.snapshot);
    this.versiculo = this.route.snapshot.data['versiculo'];
  }

  formatDate(date) {
    // const fechastringTimezone = date.substring(0, 11) + '03' + date.substring(13, 23);
    // const d =  new Date(fechastringTimezone);
    const d =  new Date(date);
    const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto',
                     'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const dayNames = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    return dayNames[d.getUTCDay()] + ' ' + d.getUTCDate() + ' de ' + monthNames[d.getUTCMonth()] + ', ' + d.getUTCFullYear();
    }


    compartir(id) {
      this.socialSharing.share('\n' + this.versiculo.texto + '\n\nCompartido desde EncuentroVida App.',
      this.versiculo.cita, null , `Continuá leyendo esta reflexión en la APP: ${APP_VERSICULO_URL}/${id}`);

    }

    fontSizeChange(val: number) {
      if (this.fontSize >= 1.9) {
        if (val === -0.1) {
          this.fontSize += val;
          return;
        }
        return;
      }
      if (this.fontSize <= 0.7 ) {
        if (val === 0.1) {
          this.fontSize += val;
          return;
        }
        return;
      }
      this.fontSize += val;
    }


    // reemplazarLibros(cita: string){
    //  switch(cita){
    //   case '':

    //  }
    // }

      // pass in the app name and the name of the user/page
    leerBiblia(app: string, name: string, fbUrl?: string) {
      switch (app) {
        case 'youversion':
          // tslint:disable-next-line: max-line-length
          this.launchApp('youversion://', 'com.youversion', 'youversion://bible?reference=' + name, 'https://www.bible.com/bible/146/' + name);
          break;

          default:
              break;
      }
    }

    private launchApp(iosApp: string, androidApp: string, appUrl: string, webUrl: string) {
        let app: string;
        // check if the platform is ios or android, else open the web url
        if (this.platform.is('android')) {
            app = androidApp;
        } else {
            const browser: InAppBrowserObject = this.inAppBrowser.create(webUrl, '_system');
            return;
        }
        this.appAvailability.check(app).then(
            () => {
                // success callback, the app exists and we can open it
                const browser: InAppBrowserObject = this.inAppBrowser.create(appUrl, '_system');
            },
            () => {
                // error callback, the app does not exist, open regular web url instead
                const browser: InAppBrowserObject = this.inAppBrowser.create(webUrl, '_system');
            }
        );
    }



}
