import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { VersiculoResolveService } from './resolver/versiculo-resolve.service';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: './pages/home/home.module#HomePageModule' },
  { path: 'acerca-de', loadChildren: './pages/acerca-de/acerca-de.module#AcercaDePageModule' },
  { path: 'cargar-motivo', loadChildren: './pages/cargar-motivo/cargar-motivo.module#CargarMotivoPageModule' },
  { path: 'encuentro-amigos', loadChildren: './pages/encuentro-amigos/encuentro-amigos.module#EncuentroAmigosPageModule' },
  { path: 'iglesia', loadChildren: './pages/iglesia/iglesia.module#IglesiaPageModule' },
  { path: 'novedades', loadChildren: './pages/novedades/novedades.module#NovedadesPageModule' },
  { path: 'quiero-asistir', loadChildren: './pages/quiero-asistir/quiero-asistir.module#QuieroAsistirPageModule' },
  { path: 'transmision-vivo', loadChildren: './pages/transmision-vivo/transmision-vivo.module#TransmisionVivoPageModule' },
  { path: 'tv', loadChildren: './pages/tv/tv.module#TvPageModule' },
  { path: 'versiculo-del-dia', loadChildren: './pages/versiculo-del-dia/versiculo-del-dia.module#VersiculoDelDiaPageModule' },
  { path: 'admin-login', loadChildren: './pages/admin-login/admin-login.module#AdminLoginPageModule' },
  {
    path: 'versiculo-del-dia/:id',
    resolve: {
      versiculo: VersiculoResolveService
    },
    loadChildren: './pages/versiculo-popover/versiculo-popover.module#VersiculoPopoverPageModule'
  },
  { path: 'listado-encuentros', loadChildren: './pages/listado-encuentros/listado-encuentros.module#ListadoEncuentrosPageModule' },
  { path: 'splash', loadChildren: './pages/splash/splash.module#SplashPageModule'},
  { path: 'audio-predicas', loadChildren: './pages/audio-predicas/audio-predicas.module#AudioPredicasPageModule' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes , { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
