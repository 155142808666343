import { Component, OnInit, ViewChild } from '@angular/core';
import { EncuentrosService } from './encuentros-service';
import { CargarInteresadoService } from './cargar-interesado.service';
import { FiltroPipe } from './filtro.pipe';
import { AlertController } from '@ionic/angular';
import { IonInfiniteScroll } from '@ionic/angular';

@Component({
  selector: 'app-listado-encuentros',
  templateUrl: './listado-encuentros.page.html',
  styleUrls: ['./listado-encuentros.page.scss'],
})
export class ListadoEncuentrosPage implements OnInit {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  pagina = 1 ;
  paginasTotal: number;
  encuentros: any = [];
  public edadSeleccionada: any;
  public generoSeleccionado: any;

  constructor(private encuentrosService: EncuentrosService,
              private interesadoService: CargarInteresadoService,
              public filtroPipe: FiltroPipe,
              public alertCtrl: AlertController) {

    // this.edadSeleccionada = 'Todos';
    // this.generoSeleccionado = 'Todos';
    // console.log('Llamando al servicio para obtener novedades');
    // // this.encuentrosService.loadListadoEncuentros(false).subscribe(
    // this.encuentrosService.loadListadoEncuentrosWp(false).subscribe(
    //   data => {
    //     console.log(data);
    //     this.encuentros = data;
    //   },
    //   err => console.error(err),
    //   () => console.log('loadListadoEncuentros completado')
    // );
   }

   ionRefresh(event2) {
    console.log('Pull Event Triggered!');
    setTimeout(() => {
      console.log('Async operation has ended');
      // this.encuentrosService.loadListadoEncuentros(event).subscribe(
      this.encuentrosService.loadListadoEncuentrosWp(1, event2).subscribe(
        (        data: { json: () => void; }) => {
          this.encuentros = data;
        },
        err => console.error(err),
        () => console.log('buscarNovedades completed')
      );
      // complete()  signify that the refreshing has completed and to close the refresher
      event2.target.complete();
      this.infiniteScroll.disabled = false;
      this.pagina = 1;
    }, 2000);
  }

  ngOnInit() {
    this.edadSeleccionada = 'Todos';
    this.generoSeleccionado = 'Todos';
    console.log('Llamando al servicio para obtener novedades');
    console.log('Llamando al servicio para obtener novedades');
    // Traigo la cantidad de paginas totales de wordpress. Dato que viene en el header http.
    this.encuentrosService.getPaginas().subscribe(
      paginas => {
        // tslint:disable-next-line: radix
        this.paginasTotal = Number.parseInt(paginas.headers.get('x-wp-totalpages'));
        console.log(this.paginasTotal);

      },
      err => console.error(err),
      () => console.log('getPaginas completado')
    );
    // Traigo los datos de novedades desde la API
    console.log('Llamando al servicio para obtener novedades');
    this.encuentrosService.loadListadoEncuentrosWp(this.pagina, false).subscribe(
      data => {
        this.encuentros = data;
      },
      err => console.error(err),
      () => console.log('loadListadoEncuentros completado')
    );

   }

   public cargarMas(event) {
    this.pagina++;
    console.log('Total Pags:  ' + this.paginasTotal);
    console.log('Pagina:  ' + this.pagina);
    if (this.pagina > this.paginasTotal) {
      event.target.complete();
      this.infiniteScroll.disabled = true;
      return;
    }
    this.encuentrosService.loadListadoEncuentrosWp(this.pagina, false).subscribe(
      data => {
        this.encuentros = [...this.encuentros, ...data];
      },
      err => console.error(err),
      () => console.log('loadVersiculos completado')
    );
    event.target.complete();
  }

  async showPrompt(e) {
    const prompt = await this.alertCtrl.create({
      header: 'Asistir',
      message: 'Ingresá tu nombre y tu teléfono y te contactaremos para que puedas '
                + 'asistir al encuentro de amigos que elegiste.',
      inputs: [
        {
          name: 'nombre',
          placeholder: 'Nombre'
        }, {
          name: 'telefono',
          placeholder: 'Teléfono'
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          handler: data => {
            console.log('Cancelar clicked');
          }
        },
        {
          text: 'Enviar',
          handler: data => {
            console.log('Enviar clicked');
            const interesado = {
              anfitrion: e.anfitrion,
              edad: e.edad,
              genero: e.genero,
              dia: e.dia,
              horario: e.horario,
              lugar: e.lugar,
              nombreInteresado: data.nombre,
              telefonoInteresado: data.telefono
            }
            console.table(interesado);
            // this.encuentrosService.postPosibleAsistente(interesado);
            this.interesadoService.postPosibleAsistente(interesado);
          }
        }
      ]

    });
    await prompt.present();
  }

}
