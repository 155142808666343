import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpModule } from '@angular/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
// import { ParallaxHeaderDirective } from './directives/parallax-header.directive';

import { HttpClientModule} from '@angular/common/http';

//

// IMPORTS DE SERVICIOS
import { TransmisionVivoService } from './pages/transmision-vivo/transmision-vivo.service';
import { EncuentrosService } from './pages/listado-encuentros/encuentros-service';


// MODULOS A IMPORTAR INSTALAR Y AGREGAR:
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { CacheModule } from 'ionic-cache';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';


// Páginas
import { HomePageModule } from './pages/home/home.module';
import { NovedadesPageModule } from './pages/novedades/novedades.module';
import { TransmisionVivoPageModule } from './pages/transmision-vivo/transmision-vivo.module';
import { YoutubeVideoPlayer } from '@ionic-native/youtube-video-player/ngx';
import { CargarMotivoPageModule } from './pages/cargar-motivo/cargar-motivo.module';
import { VersiculoDelDiaPageModule } from './pages/versiculo-del-dia/versiculo-del-dia.module';
import { VersiculoPopoverPageModule } from './pages/versiculo-popover/versiculo-popover.module';
import { TvPageModule } from './pages/tv/tv.module';
import { IglesiaPageModule } from './pages/iglesia/iglesia.module';
import { ListadoEncuentrosPageModule } from './pages/listado-encuentros/listado-encuentros.module';
import { EncuentroAmigosPageModule } from './pages/encuentro-amigos/encuentro-amigos.module';
import { AdminLoginPageModule } from './pages/admin-login/admin-login.module';
import { AudioPredicasPageModule } from './pages/audio-predicas/audio-predicas.module';

import { SplashPageModule } from './pages/splash/splash.module';
import { FiltroPipe } from './pages/listado-encuentros/filtro.pipe';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';


@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [

  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    CacheModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    // tslint:disable-next-line: deprecation
    HttpModule,
    SplashPageModule,
    HomePageModule,
    NovedadesPageModule,
    TransmisionVivoPageModule,
    CargarMotivoPageModule,
    VersiculoDelDiaPageModule,
    VersiculoPopoverPageModule,
    TvPageModule,
    AdminLoginPageModule,
    AudioPredicasPageModule,
    IglesiaPageModule,
    ListadoEncuentrosPageModule,
    EncuentroAmigosPageModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    StatusBar,
    FiltroPipe,
    AppAvailability,
    InAppBrowser,
    SocialSharing,
    OneSignal,
    TransmisionVivoService,
    EncuentrosService,
    YoutubeVideoPlayer,
    CallNumber,
    File,
    FileOpener,
    // tslint:disable-next-line: deprecation
    FileTransfer,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Deeplinks,
  ],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule { }
