import { Injectable } from '@angular/core';
import { CacheService } from 'ionic-cache';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { API_AUDIOS } from '../../../config';


@Injectable({
  providedIn: 'root'
})
export class AudioPredicasService {

  audioPredicas: any;
  url: string = API_AUDIOS;

  constructor(private http: HttpClient, private cache: CacheService) { }



  // Load either from API or Cache
  public loadAudios(refresher?) {
    this.http.get(this.url).pipe(
      map(data => {
        console.log(data);
        return data;
      }),
      catchError(error => {
        return Observable.throw('Something went wrong ;)');
      })
    );

    const ttl = 5;

    if (refresher) {
      console.log('Buscando audios en la API, a pedido del usuario.');
      const delayType = 'all';
      this.audioPredicas = this.cache.loadFromDelayedObservable(this.url, this.http.get(this.url), null, ttl, delayType);
      this.audioPredicas.subscribe(data => {
        refresher.target.complete();
      });

    } else {
      console.log('Buscando novedades en la API');
      this.audioPredicas = this.cache.loadFromObservable(this.url, this.http.get(this.url));
    }

    return this.audioPredicas;
    }


}
