import { Injectable } from '@angular/core';
import { CacheService } from 'ionic-cache';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { API_WP_BOSQUEJOS, API_BOSQUEJOS, API_WP_MEDIA } from '../../../config';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BosquejosService {

  bosquejos: any;
  // url = API_BOSQUEJOS;
  url = API_WP_BOSQUEJOS;
  // urlMedia = API_WP_MEDIA;
  req = this.http.get(this.url);


  constructor(private http: HttpClient, private cache: CacheService) {
  }


  public getVersiculos(){
    return this.http.get(this.url).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return Observable.throw('Something went wrong loading bosquejo from API ;)');
      })
    );

  }

// Load either from API or Cache
// public loadNovedades(refresher?) {
//   this.http.get(this.url).pipe(
//     map(data => {
//       return data;
//     }),
//     catchError(error => {
//       return Observable.throw('Something went wrong ;)');
//     })
//   );

//   const ttl = 5;

//   if (refresher) {
//     console.log('Buscando bosquejos en la API, a pedido del usuario.');
//     const delayType = 'all';
//     this.bosquejos = this.cache.loadFromDelayedObservable(this.url, this.http.get(this.url), null, ttl, delayType);
//     this.bosquejos.subscribe(data => {
//       refresher.target.complete();
//     });

//   } else {
//     console.log('Buscando bosquejos en la API');
//     this.bosquejos = this.cache.loadFromObservable(this.url, this.http.get(this.url));
//   }

//   return this.bosquejos;
//   }

  public getPaginas() {
    return this.http.get<any[]>(`${this.url}1`,{ observe: 'response' }).pipe(tap(res => {
      const paginasTotal  = res.headers.get('x-wp-totalpages');
      console.log('muestro mi total de paginas traido desde el header: ' + paginasTotal);
      return paginasTotal;
    }));
  }

  public loadNovedadesWP(pagina, refresher?) {
    this.http.get(this.url).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return Observable.throw('Something went wrong ;)');
      })
    );

    const ttl = 5;

    if (refresher) {
      console.log('Buscando bosquejos en la API, a pedido del usuario.');
      const delayType = 'all';
      // tslint:disable-next-line: max-line-length
      this.bosquejos = this.cache.loadFromDelayedObservable(this.url + pagina, this.http.get(this.url + pagina), null, ttl, delayType);
      this.bosquejos.subscribe(data => {
        refresher.target.complete();
      });

    } else {
      console.log('Buscando bosquejos en la API');
      this.bosquejos = this.cache.loadFromObservable(this.url + pagina, this.http.get(this.url + pagina));
    }

    return this.bosquejos;
    }
}
