import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'rxjs/operator/filter';
import { map } from 'rxjs/operator/map';

@Pipe({
  name: 'filtro'
})
export class FiltroPipe implements PipeTransform {

  transform(value, args) {
    if(!args[0]) {
      return value;
    } else if (value) {
      return value.filter(item => {
        for (let e in item){
          if (item['genero'] === args[0] || args[0] === 'Todos') {
            if (item['edad'] === args[1] || args[1] === 'Todos') {
              return true;
            }
          }
        }
      });
    }
  }

}
