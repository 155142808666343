import { Component, OnInit, ViewChild } from '@angular/core';
import { YoutubeVideoPlayer } from '@ionic-native/youtube-video-player/ngx';
import { TransmisionVivoService } from './transmision-vivo.service';
import { Platform } from '@ionic/angular';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { APP_TRANSMISION_URL } from '../../../config';
import { IonInfiniteScroll } from '@ionic/angular';


@Component({
  selector: 'app-transmision-vivo',
  templateUrl: './transmision-vivo.page.html',
  styleUrls: ['./transmision-vivo.page.scss'],
})
export class TransmisionVivoPage implements OnInit {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  data: any;
  videos: any[];
  videosUpcoming: any;
  videosVivo: any = [];

  nextPageToken;
  prevPageToken;
  morePages =  true;
  resultsPending: number;
  videosAgregados: number;

  constructor(private socialSharing: SocialSharing,
              private plt: Platform, private youtube: YoutubeVideoPlayer,
              private transmisionService: TransmisionVivoService) { }

  openVideo(videoId) {
    if (this.plt.is('cordova')) {
      this.youtube.openVideo(videoId.videoId);
    } else {
      window.open('https://www.youtube.com/watch?v=' + videoId.videoId);
    }
  }

  formatDate(date) {
    const d =  new Date(date);
    const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
                      'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const dayNames = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    return dayNames[d.getDay()] + ' ' + d.getDate() + ' de ' + monthNames[d.getMonth()] + ', ' + d.getFullYear();
    }

  ngOnInit() {
    console.log('estoy dentro del ngOnInit transmisión en vivo page.')
    this.transmisionService.getEventosEnVivoAhora().subscribe((v) => {
      this.videosVivo = v;
    });
    this.transmisionService.getEventosUpcoming().subscribe((v2) => {
      this.videosUpcoming = v2;
    });
    this.transmisionService.loadTransmisionesPagination('').subscribe((v3) => {
      this.data = v3;
      this.nextPageToken = v3['nextPageToken'];
      // this.prevPageToken = 'no';
      this.videos = v3['items'];
      this.resultsPending = v3['pageInfo'].totalResults;
      console.log('TOTAL RESULTS: ' + this.resultsPending);
      this.resultsPending -= this.videos.length;
      console.log('TOTAL RESULTS: ' + this.resultsPending);
    });

  }

  public cargarMas(event) {
    if (this.morePages === false) {
      console.log('paso MORE PAGES a false');
      event.target.complete();
      this.infiniteScroll.disabled = true;
      console.log('termino el infinite scroll');
      return;
    }
    this.transmisionService.loadTransmisionesPagination(this.nextPageToken).subscribe(
      data => {
        console.log('Pagina siguiente: ' + this.nextPageToken);
        console.log('Nueva pagina siguiente: ' + this.data.nextPageToken);
        if (this.resultsPending > 0) {
          if (this.data.nextPageToken === undefined) {
            this.morePages = false;
            return;
          } else {


            this.videos = [...this.videos, ...data['items']];
            this.data = data;
            console.log('TOTAL RESULTS: ' + this.resultsPending);
            this.resultsPending -= this.data['items'].length;
            console.log('TOTAL RESULTS: ' + this.resultsPending);

            if (this.data.nextPageToken){
              this.nextPageToken = this.data.nextPageToken;
              this.prevPageToken = this.data.prevPageToken;
            }
          }
          return;
        } else {
          this.morePages = false;
          return;
        }
      },
      err => console.error(err),
      () => console.log('loadVersiculos completado')
    );
    event.target.complete();
  }


  compartir(v: any){
    let tituloMayuscula: string;
    tituloMayuscula = v.snippet.title;
    const linkDescarga = 'Mirá la transmisión desde la app -> ' + APP_TRANSMISION_URL;
    this.socialSharing.share(
          'Te comparto este video!' +
          'Del día ' + this.formatDate(v.snippet.publishedAt) +
          '\n' + tituloMayuscula.toLocaleUpperCase() + '\n' +
          'https://www.youtube.com/watch?v=' + v.id.videoId +
          '\nCompartido desde EncuentroVida App.',
      v.snippet.title,
      '', linkDescarga);
  }


}
