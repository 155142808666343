import { Injectable } from '@angular/core';

@Injectable()
export class Config {}

/* CONFIGURACIÓN EN COMÚN DESA Y PROD */
export const sender_id = '983789666606';

/* PRODUCCIÓN:       */
export const oneSignalAppId = 'a02a5ef8-74f1-43b4-8f93-f9a9739145a8';

/* DESARROLLO:       */
// export const oneSignalAppId = '73084aa4-efd4-4801-9397-eb9bedbc1f34';

// Link de descarga de la app
export const APP_INSTALL_URL = 'http://m.encuentrovida.com.ar/';

// ENDPOINTS API V2
export const API_VERSICULOS_REDUCIDO = 'https://apiv2.encuentrovida.com.ar/vers/';
export const API_VERSICULOS_INDIVIDUAL = 'https://apiv2.encuentrovida.com.ar/versiculos/';
// tslint:disable-next-line: max-line-length
export const API_VERSICULOS_PAGINACION = `https://apiv2.encuentrovida.com.ar/versPubPag?limit=15&sort=fecha: -fecha&select=fecha titulo avatarurl cita requests&page=`;
// export const API_VERSICULOS_REDUCIDO = 'http://192.168.1.41:3001/vers/';


// export const API_VERSICULOS_INDIVIDUAL = 'http://192.168.1.41:3001/versiculos/';
export const API_MOTIVOS_ORACION = 'https://apiv2.encuentrovida.com.ar/motivos/';
export const API_BOSQUEJOS = 'https://apiv2.encuentrovida.com.ar/bosquejos/';
export const API_ACTIVIDADES = 'https://apiv2.encuentrovida.com.ar/actividades/';
export const API_INTERESADOS = 'https://apiv2.encuentrovida.com.ar/interesados/';
export const API_ENCUENTROS = 'https://apiv2.encuentrovida.com.ar/encuentros/';
export const API_BUSQUEDA_ENCUENTROS = 'https://apiv2.encuentrovida.com.ar/buscarEncuentros/';
export const API_NOVEDADES = 'https://apiv2.encuentrovida.com.ar/novedades/';
export const API_AUDIOS = 'https://apiv2.encuentrovida.com.ar/audios/';
export const API_TRANSMISIONES_VIVO = 'https://apiv2.encuentrovida.com.ar/transmisiones/vivo/';
export const API_TRANSMISIONES_PROXIMAS = 'https://apiv2.encuentrovida.com.ar/transmisiones/proximas/';
export const API_TRANSMISIONES_FINALIZADAS = 'https://apiv2.encuentrovida.com.ar/transmisiones/finalizadas/';
export const API_TV_PLAYLIST = 'https://apiv2.encuentrovida.com.ar/tv/playlist/';



// ENDPOINTS WORDPRESS
export const API_WP_BOSQUEJOS = 'https://encuentrovida.com.ar/wp-json/wp/v2/bosquejo?page=';
export const API_WP_ENCUENTROS = 'https://encuentrovida.com.ar/wp-json/wp/v2/encuentro?page=';
export const API_WP_NOVEDADES = 'https://encuentrovida.com.ar/wp-json/wp/v2/novedad?page=';
export const API_WP_MEDIA = 'https://encuentrovida.com.ar/wp-json/wp/v2/media/';

// ASSETS REMOTOS
export const ASSET_APP_SHARE_GIF = 'https://assets.encuentrovida.com.ar/app/AppShare.gif';
export const ASSET_FOTO_IGLESIA = 'https://assets.encuentrovida.com.ar/app/iglesia.jpg';

// URL PARA DEEPLINKIKNG
export const APP_VERSICULO_URL = 'https://app.encuentrovida.com.ar/v';
export const APP_ORACION_URL = 'https://app.encuentrovida.com.ar/o';
export const APP_TRANSMISION_URL = 'https://app.encuentrovida.com.ar/t';
export const APP_ENCUENTROS_URL = 'https://app.encuentrovida.com.ar/e';
export const APP_NOVEDADES_URL = 'https://app.encuentrovida.com.ar/n';
export const APP_AUDIO_PREDICAS_URL = 'https://app.encuentrovida.com.ar/a';
export const APP_TELEVISION_URL = 'https://app.encuentrovida.com.ar/tv';
