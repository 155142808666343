import { Injectable } from '@angular/core';
import { CacheService } from 'ionic-cache';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { API_TV_PLAYLIST } from '../../../config';

@Injectable({
  providedIn: 'root'
})
export class TvService {

// tslint:disable-next-line: max-line-length
// https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=PLyzv0Y1isT7pIp1InBI9pgbtpDepmZACh&key=AIzaSyBD2bVYXv0ve_CSHDafRpogNUdNtOh6QYE


  apiKey = 'AIzaSyBD2bVYXv0ve_CSHDafRpogNUdNtOh6QYE';
  playlistId = 'PLyzv0Y1isT7pIp1InBI9pgbtpDepmZACh';
  videos: any;

  constructor(private http: HttpClient) { }


  // public getVideosTv() {
  //   console.log('Consulto Youtube API videos de playlist: ');
  //   return this.http.get('https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&key='
  //                       + this.apiKey + '&maxResults=50&part=snippet&playlistId='
  //                       + this.playlistId +
  //                       '&order=date')
  //     .pipe(map(data => {
  //       console.log(data);
  //       return data['items'];
  //     }),
  //     catchError(error => {
  //       return Observable.throw('Something went wrong ;)');
  //     })
  //   );
  // }
  
  public getVideosTv() {
    console.log('Consulto Youtube API videos de playlist: ');
    return this.http.get(API_TV_PLAYLIST)
      .pipe(map(data => {
        console.log(data);
        return data['items'];
      }),
      catchError(error => {
        return Observable.throw('Something went wrong ;)');
      })
    );
  }

}
