import { Component, OnInit } from '@angular/core';
import { IglesiaService } from './iglesia.service';
import { AlertController } from '@ionic/angular';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { InAppBrowserOptions, InAppBrowser, InAppBrowserObject } from '@ionic-native/in-app-browser/ngx';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-iglesia',
  templateUrl: './iglesia.page.html',
  styleUrls: ['./iglesia.page.scss'],
})
export class IglesiaPage implements OnInit {

  actividades: any;

  mapLink = 'https://goo.gl/maps/6CpspWMo5eK2';

  options: InAppBrowserOptions = {
    location : 'yes', // Or 'no'
    hidden : 'no', // Or  'yes'
    clearcache : 'yes',
    clearsessioncache : 'yes',
    zoom : 'no', // Android only ,shows browser zoom controls
    hardwareback : 'yes',
    mediaPlaybackRequiresUserAction : 'no',
    shouldPauseOnSuspend : 'no', // Android only
    closebuttoncaption : 'Close', // iOS only
    disallowoverscroll : 'no', // iOS only
    toolbar : 'yes', // iOS only
    enableViewportScale : 'no', // iOS only
    allowInlineMediaPlayback : 'no', // iOS only
    presentationstyle : 'pagesheet', // iOS only
    fullscreen : 'yes', // Windows only
  };

  constructor(public iglesiaService: IglesiaService,
              public alertController: AlertController,
              public inAppBrowser: InAppBrowser,
              private platform: Platform,
              private appAvailability: AppAvailability,
              private callNumber: CallNumber) {

    console.log('Llamando al servicio para obtener novedades');
    this.iglesiaService.loadNovedades(false).subscribe(
      data => {
        console.log(data);
        this.actividades = data;
      },
      err => console.error(err),
      () => console.log('loadNovedades completado')
    );
    console.log(this.actividades);
   }

   ionRefresh(event) {
    console.log('Pull Event Triggered!');
    setTimeout(() => {
      console.log('Async operation has ended');
      this.iglesiaService.loadNovedades(event).subscribe(
        (        data: { json: () => void; }) => {
          this.actividades = data;
        },
        err => console.error(err),
        () => console.log('buscarNovedades completed')
      );
      // complete()  signify that the refreshing has completed and to close the refresher
      event.target.complete();
    }, 2000);
  }


  // pass in the app name and the name of the user/page
  openUrl(app: string, name: string, fbUrl?: string) {
    switch (app) {
        case 'facebook':
            this.launchApp('fb://', 'com.facebook.katana', 'fb://facewebmodal/f?href=' + fbUrl, 'https://www.facebook.com/' + name);
            break;

        case 'instagram':
            // tslint:disable-next-line: max-line-length
            this.launchApp('instagram://', 'com.instagram.android', 'instagram://user?username=' + name, 'https://www.instagram.com/' + name);
            break;

        case 'whatsapp':
            // tslint:disable-next-line: max-line-length
            this.launchApp('whatsapp://', 'com.whatsapp', 'whatsapp://send?phone=' + name , 'https://wa.me/541125864722');
            break;

        default:
            break;
    }
}

private launchApp(iosApp: string, androidApp: string, appUrl: string, webUrl: string) {
    let app: string;
    // check if the platform is ios or android, else open the web url
    if (this.platform.is('android')) {
        app = androidApp;
    } else {
        const browser: InAppBrowserObject = this.inAppBrowser.create(webUrl, '_system');
        return;
    }
    this.appAvailability.check(app).then(
        () => {
            // success callback, the app exists and we can open it
            const browser: InAppBrowserObject = this.inAppBrowser.create(appUrl, '_system');
        },
        () => {
            // error callback, the app does not exist, open regular web url instead
            const browser: InAppBrowserObject = this.inAppBrowser.create(webUrl, '_system');
        }
    );
}


  ngOnInit() {
  }

  compartir() {

  }

  abrirPerfilSocial() {

  }

  async presentConfirm() {
    const alert = await this.alertController.create({
      header: 'Se llamará ahora mismo',
      message: '¿Desea continuar?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Llamar',
          handler: () => {
            console.log('Llamada confirmada');
            this.llamarPorTelefono();
          }
        }
      ]
    });

    await alert.present();
  }


  llamarPorTelefono() {
    this.callNumber.callNumber('+54 011 22038334', true)
      .then(() => console.log('Launched dialer!'))
      .catch(() => console.log('Error launching dialer'));
  }

  abrirMapa() {
    // const browser = this.inAppBrowser.create(this.mapLink, null, this.options);
    // browser.show();
    this.appAvailability.check('maps').then(
      () => {
          // success callback, the app exists and we can open it
          const browser: InAppBrowserObject = this.inAppBrowser.create(this.mapLink, '_system');
      },
      () => {
          // error callback, the app does not exist, open regular web url instead
          const browser: InAppBrowserObject = this.inAppBrowser.create(this.mapLink, '_system');
      }
    );
  }

}
