import { Injectable } from '@angular/core';
import { CacheService } from 'ionic-cache';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { API_TRANSMISIONES_FINALIZADAS,
  API_TRANSMISIONES_VIVO,
  API_TRANSMISIONES_PROXIMAS } from '../../../config';


@Injectable({
  providedIn: 'root'
})
export class TransmisionVivoService {

  videos: any;

  constructor(private http: HttpClient) {

  }


  public getEventosTransmitidos() {
      console.log('Consulto Youtube API completed Live events: ');
      return this.http.get(API_TRANSMISIONES_FINALIZADAS)
        .pipe(map(data => {
          console.log('Dentro del servicio: BUSCO COMPLETED EVENTS');
          console.log(data);
          return data;
          // return data['items'];
        }),
        catchError(error => {
          return Observable.throw('Something went wrong ;)');
        })
      );
    }
  
    public getEventosUpcoming() {
      console.log('Consulto Youtube API completed Live events: ');
      return this.http.get(API_TRANSMISIONES_PROXIMAS) // Proxima transmisión, devuelve 1, el mas nuevo
        .pipe(map(data => {
          console.log('Dentro del servicio: BUSCO UPCOMING EVENTS');
          console.log(data);
          // return data['items'];
          return data;
        }),
        catchError(error => {
          return Observable.throw('Something went wrong :\')');
        })
      );
    }
  
    public getEventosEnVivoAhora() {
      console.log('Consulto Youtube API Live events: ');
      return this.http.get(API_TRANSMISIONES_VIVO) // Video en VIVO, devuelve 1, el mas nuevo
        .pipe(map(data => {
          console.log('Dentro del servicio: BUSCO LIVE EVENTS');
          console.log(data);
          // return data['items'];
          return data;
        }),
        catchError(error => {
          return Observable.throw('Algo anduvo mal ;)');
        })
      );
    }

    public loadTransmisionesPagination(pagina) {
      console.log('Consulto Youtube API Live events: ');
      return this.http.get(API_TRANSMISIONES_FINALIZADAS + pagina)
        .pipe(map(data => {
          console.log('Dentro del servicio: BUSCO FINALIZADAS paginacion');
          console.log(data);
          return data;
        }),
        catchError(error => {
          return Observable.throw('Algo anduvo mal ;)');
        })
      );
    }

}
