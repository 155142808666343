import { Injectable } from '@angular/core';
import { CacheService } from 'ionic-cache';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { API_VERSICULOS_REDUCIDO, API_VERSICULOS_PAGINACION } from '../../../config';


@Injectable({
  providedIn: 'root'
})
export class VersiculoDelDiaService {

  versiculos: any;
  url: string = API_VERSICULOS_REDUCIDO;
  urlPaginacion: string = API_VERSICULOS_PAGINACION;

  req = this.http.get(this.url);


  constructor(private http: HttpClient, private cache: CacheService) {
  }


  public getVersiculos(){
    return this.http.get(this.url).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return Observable.throw('Something went wrong ;)');
      })
    );

  }


  public getVersiculosPagination(numeroPagina: string){
    return this.http.get(this.urlPaginacion + numeroPagina).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return Observable.throw('Something went wrong ;)');
      })
    );

  }


  /********************************************************** */

// Load either from API or Cache
public loadNovedades(pagina, refresher?) {
  this.http.get(this.urlPaginacion).pipe(
    map(data => {
      return data;
    }),
    catchError(error => {
      return Observable.throw('Something went wrong ;)');
    })
  );

  const ttl = 5;

  if (refresher) {
    console.log('Buscando novedades en la API, a pedido del usuario.');
    const delayType = 'all';
    // tslint:disable-next-line: max-line-length
    this.versiculos = this.cache.loadFromDelayedObservable(this.urlPaginacion + pagina, this.http.get(this.urlPaginacion + pagina), null, ttl, delayType);
    this.versiculos.subscribe(data => {
      refresher.target.complete();
    });

  } else {
    console.log('Buscando novedades en la API');
    this.versiculos = this.cache.loadFromObservable(this.urlPaginacion + pagina, this.http.get(this.urlPaginacion + pagina));
  }
  return this.versiculos;
  }


  public loadVersiculosPagination(pagina: number, refresher?) {
    this.http.get(this.url).pipe(
      map(data => {
        return data;
      }),
      catchError(error => {
        return Observable.throw('Something went wrong ;)');
      })
    );
  
    const ttl = 5;
  
    if (refresher) {
      console.log('Buscando novedades en la API, a pedido del usuario.');
      const delayType = 'all';
      // tslint:disable-next-line: max-line-length
      this.versiculos = this.cache.loadFromDelayedObservable(this.urlPaginacion + pagina, this.http.get(this.urlPaginacion + pagina), null, ttl, delayType);
      this.versiculos.subscribe(data => {
        refresher.target.complete();
      });
  
    } else {
      console.log('Buscando novedades en la API');
      this.versiculos = this.cache.loadFromObservable(this.urlPaginacion + pagina, this.http.get(this.urlPaginacion + pagina));
    }
    // console.log('VERSICULOS PAGINACION: ' + JSON.stringify(this.versiculos));
    return this.versiculos;
    }


}
