import { Component, OnInit, ViewChild } from '@angular/core';
import { Platform, ActionSheetController } from '@ionic/angular';
import { YoutubeVideoPlayer } from '@ionic-native/youtube-video-player/ngx';
import { BosquejosService } from './bosquejos.service';
import * as moment from 'moment';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { ToastController } from '@ionic/angular';
import { APP_ENCUENTROS_URL } from '../../../config';
import { IonInfiniteScroll } from '@ionic/angular';


@Component({
  selector: 'app-encuentro-amigos',
  templateUrl: './encuentro-amigos.page.html',
  styleUrls: ['./encuentro-amigos.page.scss'],
})
export class EncuentroAmigosPage implements OnInit {

  bosquejos: any;
  bosquejoLink: any;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  pagina = 1 ;
  paginasTotal: number;

  fileTransfer: FileTransferObject = this.transfer.create();
  fileTransfer2: FileTransferObject;

  options: InAppBrowserOptions = {
    location: 'no',
    hidden: 'no',
    clearcache: 'yes',
    clearsessioncache: 'yes',
    zoom: 'no',
    hardwareback: 'yes',
    mediaPlaybackRequiresUserAction: 'no',
    shouldPauseOnSuspend: 'no',
    closebuttoncaption: 'Close',
    disallowoverscroll: 'no',
    toolbar: 'yes',
    enableViewportScale: 'no',
    allowInlineMediaPlayback: 'no',
    presentationstyle: 'pagesheet',
    fullscreen: 'yes',
  };


  constructor(private plt: Platform,
              private youtube: YoutubeVideoPlayer,
              public actionSheetController: ActionSheetController,
              private inAppBrowser: InAppBrowser,
              private socialSharing: SocialSharing,
              public bosquejosService: BosquejosService,
              public toastCtrl: ToastController,
              // tslint:disable-next-line: deprecation
              private transfer: FileTransfer,
              private file: File,
              private fileOpener: FileOpener
              ) {
    // console.log('Llamando al servicio para obtener novedades');
    // this.bosquejosService.loadNovedades(false).subscribe(
    //   data => {
    //     console.log(data);
    //     console.log('muestro la tabla debajo: ');
    //     console.table(data);
    //     this.bosquejos = data;
    //   },
    //   err => console.error(err),
    //   () => console.log('loadNovedades completado')
    // );
    // console.table(this.bosquejos);
  }

  verBosquejoNativo(url: string, title: string) {
    this.fileTransfer2 = this.transfer.create();
    this.fileTransfer2
      .download(url, this.file.dataDirectory + title + '.pdf')
      .then(entry => {
        console.log('download complete: ' + entry.toURL());
        this.fileOpener
          .open(entry.toURL(), 'application/pdf')
          .then(() => console.log('File is opened'))
          .catch(e => console.log('Error opening file', e));
      });
  }

  async presentActionSheetVideo(v) {
    const actionSheet = await this.actionSheetController.create({
      header: 'Video guía',
      buttons: [{
        text: 'Compartir',
        icon: 'share',
        handler: () => {
          this.compartirVideo(v.video);
          console.log('Share clicked');
        }
      }, {
        text: 'Reproducir',
        icon: 'arrow-dropright-circle',
        handler: () => {
          this.verVideo(v.video);
          console.log('Play clicked');
        }
      }, {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]
    });
    await actionSheet.present();
  }

  async presentActionSheetBosquejo(b) {
    const actionSheet = await this.actionSheetController.create({
      header: 'Bosquejo escrito',
      buttons: [{
        text: 'Compartir',
        icon: 'share',
        handler: () => {
          console.log('Share clicked');
          this.compartirBosquejo(b.bosquejo);
        }
      }, {
        text: 'Abrir',
        icon: 'document',
        handler: () => {
          console.log('Abrir clicked');
          // this.verBosquejo(b.bosquejo);
          this.verBosquejoNativo(b.bosquejo, this.bosquejoFileName(b.fecha));
        }
      }, {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]
    });
    await actionSheet.present();
  }

  async mostrarToast(mensaje) {
    const toast = await this.toastCtrl.create({
      message: mensaje,
      duration: 3000
    });
    toast.present();
  }

  public verVideo(video) {
    if (this.plt.is('cordova')) {
      this.youtube.openVideo(video);
    } else {
      window.open('https://www.youtube.com/watch?v=' + video);
    }
  }

  buscarNovedades(refresher) {
    console.log('Begin async operation', refresher);
    console.log('El usuario quiere mas novedades... ');
    this.bosquejosService.loadNovedadesWP(1, refresher).subscribe(
      data => {
        this.bosquejos = data;
      },
      err => console.error(err),
      () => console.log('buscarNovedades completed')
    );
    setTimeout(() => {
      console.log('Async operation has ended');
      refresher.target.complete();
      this.infiniteScroll.disabled = false;
      this.pagina = 1;
    }, 2000);
  }

  public formatDate(date) {
    const d = new Date(date);
    const semana = moment(date).format('W');
    return 'Año ' + d.getFullYear() + ', Semana ' + semana;
  }

  public bosquejoFileName(date) {
    const d = new Date(date);
    const semana = moment(date).format('W');
    return 'Bosquejo - semana ' + semana;
  }

  public compartirBosquejo(b: any) {
    this.socialSharing.share(
      'Te comparto este bosquejo de encuentro de amigos!' +
      '\n' + b + '\n' +
      '\nCompartido desde EncuentroVida App. Mirá este bosquejo desde la app -> ' + APP_ENCUENTROS_URL,
    );
  }

  public compartirVideo(v: any) {
    this.socialSharing.share(
      'Te comparto este video de encuentro de amigos!' +
      '\n---> https://youtu.be/' + v + '\n' +
      '\nCompartido desde EncuentroVida App. Mira este video desde la app -> ' + APP_ENCUENTROS_URL,
    );
  }

  ngOnInit() {
    console.log('Llamando al servicio para obtener bosquejos');
    // Traigo la cantidad de paginas totales de wordpress. Dato que viene en el header http.
    this.bosquejosService.getPaginas().subscribe(
      paginas => {
        // tslint:disable-next-line: radix
        this.paginasTotal = Number.parseInt(paginas.headers.get('x-wp-totalpages'));
        console.log(this.paginasTotal);

      },
      err => console.error(err),
      () => console.log('getPaginas completado')
    );
    // Traigo los datos de novedades desde la API
    console.log('Llamando al servicio para obtener bosquejos');
    this.bosquejosService.loadNovedadesWP(this.pagina, false).subscribe(
      data => {
        this.bosquejos = data;
      },
      err => console.error(err),
      () => console.log('loadBosquejos completado')
    );
  }

  public cargarMas(event) {
    this.pagina++;
    console.log('Total Pags:  ' + this.paginasTotal);
    console.log('Pagina:  ' + this.pagina);
    if (this.pagina > this.paginasTotal) {
      event.target.complete();
      this.infiniteScroll.disabled = true;
      return;
    }
    this.bosquejosService.loadNovedadesWP(this.pagina, false).subscribe(
      data => {
        this.bosquejos = [...this.bosquejos, ...data];
      },
      err => console.error(err),
      () => console.log('loadVersiculos completado')
    );
    event.target.complete();
  }

}
